<template>
  <div>
    <c-table
      ref="equipTable"
      title="장비 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      selection="multiple"
      rowKey="heaClosedSpaceEquipmentId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'closedSpaceEquipStockPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        columns: [
        {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '장비종류',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '장비명',
            align: 'left',
            sortable: true,
          },
          // {
          //   name: 'equipmentStockNumber',
          //   field: 'equipmentStockNumber',
          //   label: '창고 재고량',
          //   align: 'right',
          //   sortable: true,
          // },
          {
            name: 'unitName',
            field: 'unitName',
            label: 'LBLUNIT',
            align: 'center',
            style: 'width:90px',
            sortable: false,
            setHeader: true,
          },
        ],
        data: [],
        height: '450px'
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.cse.equip.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['equipTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '장비를 선택하세요.', // 설비를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
